<template lang="pug">
    base-layout
        template(slot="main")
            div.inform.text-center
                div.inform__auth
                    div.auth__block.auth_desktop(v-show="isShowLogin")
                        auth(v-on:showLogin="showLogin")
                p.inform__pink(style="margin-top:50px;margin-bottom:50px") Победитель акции <br> <span style="font-size:50px">ЛЯЙСАН ШАДРИНА</span>
                    //- button.button-pink.login-trigger.mb-24.hide-mobile(@click="showLogin()") участвовать!
                p.inform__text.mb-5 Телеканал RU.TV совместно с ООО «ДЖАК АВТОМОБИЛЬ» дарят зрителям возможность получить новогодние подарки и стать обладателем нового автомобиля!
                p.inform__text.mb-12 Все, что нужно: смотреть телеканал RU.TV, ловить в эфире счастливые номера авто и участвовать в розыгрыше!
                p.inform__pink.mb-6 с 1 по 25 декабря 2022 г.
            div.prizes.grid.grid-cols-3
                div.prizes__el
                    span.el__num 1
                    div.el__blockimg
                        img.el__img(src="./assets/main_girl.svg")
                    span.el__text включай телеканал RU.TV и смотри музыку!
                div.prizes__el
                    span.el__num 2
                    div.el__blockimg
                        img.el__img(src="./assets/main_car.png")
                    span.el__text лови номера авто и регистрируйся на сайте акции
                div.prizes__el
                    span.el__num 3
                    div.el__blockimg
                        img.el__img(src="./assets/main_present.svg")
                    span.el__text собирай номера в личном кабинете и выигрывай призы

            div.prizes-mobile
                div.prizes__el
                    div.el__num 1
                    div.el__text включай телеканал RU.TV и смотри музыку!
                    img.el__img(src="./assets/main_girl.svg")
                div.prizes__el
                    div.el__num 2
                    div.el__text лови номера авто и регистрируйся на сайте акции
                    img.el__img(src="./assets/main_car.png" style="max-width: 75%")
                div.prizes__el
                    div.el__num 3
                    div.el__text собирай номера в личном кабинете и выигрывай призы
                    img.el__img(src="./assets/main_present.svg")


            div.inform.text-center
                p.inform__pink2 30 декабря 2022 г. 
                p.inform__pink3.mb-10 в прямом эфире телеканала RU.TV
                p.inform__text.mb-12(style="max-width:1120px") СРЕДИ ВСЕХ УЧАСТНИКОВ РОЗЫГРЫША ВЕДУЩИЕ ТЕЛЕКАНАЛА RU.TV И ПРИГЛАШЕННЫЕ АРТИСТЫ ВЫБЕРУТ СЧАСТЛИВОГО ОБЛАДАТЕЛЯ НОВОГО АВТОМОБИЛЯ!
                p.inform__text.mb-20(style="max-width:800px") 10 УЧАСТНИКОВ, СОБРАВШИХ НАИБОЛЬШЕЕ КОЛИЧЕСТВО НОМЕРОВ АВТО ЗА ВЕСЬ ПЕРИОД АКЦИИ, ПОЛУЧАТ ПОДАРОЧНЫЕ НАБОРЫ ОТ ТЕЛЕКАНАЛА RU.TV
                div.inform__auth
                    //- div.auth__block.auth_desktop_footer(v-if="isShowLogin2")
                    //-     auth(v-on:showLogin="showLogin")
                    //- button.button-pink.mb-24(@click="showLogin()") участвовать!
</template>

<script>
//Components
import BaseLayout from '@/layout/base-layout';
import auth from '@/components/common/auth';

export default {
    name: "home",
    components: {
        'base-layout': BaseLayout,
        auth
    },
    data: function(){

        return {
            loggedIn: false,
            isShowLogin: false,
            isShowLogin2: false
        }
    },
    mounted(){
        this.loggedIn = JSON.parse(localStorage.getItem('loggedIn'))
        let showHome  = JSON.parse(localStorage.getItem('showHome'))
        if (this.loggedIn && !showHome) {
            window.location.replace('/account')
        }
        localStorage.removeItem('showHome')

        this.showLogin()
    },
    methods:{
        showLogin(flag, isTwo){

            if (this.loggedIn) {
                window.location.replace('/account')
                return false
            }

            const el = document.querySelector('.auth_desktop')
            el.style.top = `${window.scrollY + 50}px`
            
            if (flag != undefined) {
                this.isShowLogin  = flag
                this.isShowLogin2 = flag
            } else {
                if (isTwo) {
                    this.isShowLogin2 = this.isShowLogin2 ? false : true
                    this.isShowLogin  = false
                } else {
                    this.isShowLogin  = this.isShowLogin ? false : true
                    this.isShowLogin2 = false
                }
                
            }

        }
    }
}
</script>

<style lang="scss" scoped>


.inform{

    .hide-mobile{
        @media (max-width:500px){
            display: none;
        }
    }

    .inform__auth{
        // position: relative;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        @media (max-width:500px){
            // max-width: 390px;
        }
        .auth__block{
            position: absolute;
            z-index: 5;
            top: -100px;
            width: 450px;
            // left: -5%;
            @media (max-width:500px){
                top: -10px;
                width: 100%;
                // left: -0.4%;
                left: 0px;
            }
        }
    }

    .inform__text{
        font-weight: 900;
        font-size: 28px;
        max-width: 1000px;
        line-height: 30px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        text-transform: uppercase;
        @media (max-width:500px){
            font-size: 16px;
            max-width: 340px !important;
            line-height: 23px;
            margin-top: 20px;
        }
    }

    .inform__pink, .inform__pink2, .inform__pink3{
        font-weight: 900;
        font-size: 35px;
        text-align: center;
        text-transform: uppercase;
        color: #E6007E;
        @media (max-width:500px){
            font-size: 22px;
        }
    }

    .inform__pink2{
        font-size: 60px;
        @media (max-width:500px){
            font-size: 35px;
        }
    }

    .inform__pink3{
        font-size: 30px;
        @media (max-width:500px){
            font-size: 24px;
        }
    }
    .wx-1105{
        max-width: 1120px !important;
    }
    .wx-800{
        max-width: 800px !important;
    }

}

.prizes-mobile{
    max-width: 390px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
    display: none;
    @media (max-width:500px){
        display: block;
    }

    .prizes__el{
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: nowrap;
        align-content: center;
        margin-bottom: 50px;

        .el__num{
            font-weight: 900;
            font-size: 50px;
            text-align: center;
            text-transform: uppercase;
            color: #E4007A;
        }

        .el__text{
            font-weight: 900;
            font-size: 16px;
            text-align: center;
            text-transform: uppercase;
            color: #E4007A;
            max-width: 220px;
            margin-bottom: 20px;
        }

    }

}

.prizes{
    max-width: 1070px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
    @media (max-width:500px){
        display: none;
    }

    .prizes__el{

        .el__num{
            font-weight: 900;
            position: relative;
            font-size: 100px;
            text-align: center;
            left: 75px;
            top: 10px;
            text-transform: uppercase;
            color: #E4007A;
        }

        &:first-of-type{
            .el__num{
                left: 50px !important;
                top: 0px !important;
            }
        }

        &:last-of-type{
            .el__num{
                top: 0px !important;
                left: 80px !important;
            }
        }
        .el__blockimg{
            position: relative;
            height: 145px;
            margin-bottom: 20px;
        }
        
        .el__img{
            position: absolute;
            top: -40px;
            margin: auto;
            height: 150px;
            margin-bottom: 45px;
            left: 13%;
        }
        &:first-of-type{
            .el__img{
                top: -45px !important;
            }
        }
        &:last-of-type{
            .el__img{
                height: 196px !important;
                top: -82px !important;
                left: 23%;
            }
        }
        .el__text{
            font-weight: 900;
            font-size: 24px;
            min-height: 75px;
            line-height: 25px;
            display: inline-block;
            padding: 0px 20px;
            text-align: center;
            text-transform: uppercase;
            color: #E4007A;
        }

    }

    
}
</style>